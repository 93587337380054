import {Box, Typography} from '@mui/material'
// import mockData from '../../mock/mock-data.json'
import audioFiles from '../../mock/mock-data.json'
import {useState, useRef} from 'react'

const Home = () => {
  const [playingIndex, setPlayingIndex] = useState(null)
  const audioRef = useRef(new Audio())

  const playAudio = (index: any) => {
    if (playingIndex === index) {
      // Si el mismo audio se está reproduciendo, detenlo
      audioRef.current.pause()
      setPlayingIndex(null)
    } else {
      // Detén cualquier audio que se esté reproduciendo actualmente
      if (playingIndex !== null) {
        audioRef.current.pause()
      }

      // Reproduce el audio seleccionado y actualiza el estado
      audioRef.current.src = audioFiles[index].src
      audioRef.current.play()
      setPlayingIndex(index)
    }
  }

  return (
    <Box className="PageContainer">
      {audioFiles.map((item, index) => (
        <Box
          key={item.title}
          onClick={() => playAudio(index)}
          className="ArrayContainer"
        >
          <Typography variant="subtitle1" fontWeight="bold">
            {item.title}
          </Typography>
          {/* <Typography variant="body2">{item.description}</Typography> */}
        </Box>
      ))}
    </Box>
  )
}

export default Home
